// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import Terminals from './components/Terminals';
import Templates from './components/Templates';

function App() {
  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Управление Конфигурациями
          </Typography>
          <Button color="inherit" component={Link} to="/">
            Терминалы
          </Button>
          <Button color="inherit" component={Link} to="/templates">
            Шаблоны
          </Button>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Terminals />} />
        <Route path="/templates" element={<Templates />} />
      </Routes>
    </Router>
  );
}

export default App;
