// src/components/Terminals.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import axios from 'axios';

function Terminals() {
  const [terminals, setTerminals] = useState([]);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchTerminals();
    fetchTemplates();
  }, []);

  const fetchTerminals = async () => {
    try {
      const response = await axios.get('/api/v1/terminal/list');
      const terminalsList = response.data;

      const terminalsWithConfig = await Promise.all(
        terminalsList.map(async (terminalId) => {
          try {
            const configResponse = await axios.get('/api/v1/terminal/current', {
              params: { terminal_id: terminalId },
            });
            return {
              terminalId,
              configName: configResponse.data.config_name || 'default',
            };
          } catch (error) {
            // Если конфигурация не назначена, установить 'default'
            return {
              terminalId,
              configName: 'default',
            };
          }
        })
      );

      setTerminals(terminalsWithConfig);
    } catch (error) {
      console.error('Ошибка при получении терминалов:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/v1/config/list');
      setTemplates(response.data);
    } catch (error) {
      console.error('Ошибка при получении шаблонов:', error);
    }
  };

  const handleTemplateChange = async (terminalId, newTemplate) => {
    try {
      await axios.post('/api/v1/terminal/config/set', {
        terminal_id: terminalId,
        name: newTemplate,
      });
      setTerminals((prevTerminals) =>
        prevTerminals.map((terminal) =>
          terminal.terminalId === terminalId
            ? { ...terminal, configName: newTemplate }
            : terminal
        )
      );
    } catch (error) {
      console.error('Ошибка при изменении шаблона терминала:', error);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Список Терминалов
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Терминала</TableCell>
              <TableCell>Шаблон</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terminals.map((terminal) => (
              <TableRow key={terminal.terminalId}>
                <TableCell>{terminal.terminalId}</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Шаблон</InputLabel>
                    <Select
                      value={terminal.configName}
                      label="Шаблон"
                      onChange={(e) =>
                        handleTemplateChange(terminal.terminalId, e.target.value)
                      }
                    >
                      {templates.map((template) => (
                        <MenuItem key={template} value={template}>
                          {template}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}

export default Terminals;
