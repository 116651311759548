// src/components/Templates.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@mui/material';
import axios from 'axios';
import yaml from 'js-yaml';

function Templates() {
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({ name: '', compose: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/v1/config/list');
      setTemplates(response.data);
    } catch (error) {
      console.error('Ошибка при получении шаблонов:', error);
    }
  };

  const handleOpen = async (name) => {
    if (name) {
      setIsEditing(true);
      try {
        const response = await axios.get('/api/v1/config/get', {
          params: { name },
        });
        const yamlData = yaml.dump(response.data.compose);
        setCurrentTemplate({ name: response.data.name, compose: yamlData });
      } catch (error) {
        console.error('Ошибка при получении шаблона:', error);
      }
    } else {
      setIsEditing(false);
      setCurrentTemplate({ name: '', compose: '' });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTemplate({ name: '', compose: '' });
  };

  const handleSave = async () => {
    try {
      const jsonData = yaml.load(currentTemplate.compose);
      await axios.post('/api/v1/config/insert', {
        name: currentTemplate.name,
        compose: jsonData,
      });
      fetchTemplates();
      handleClose();
    } catch (error) {
      console.error('Ошибка при сохранении шаблона:', error);
      alert('Ошибка валидации YAML');
    }
  };

  const handleDelete = async (name) => {
    // Предполагается, что существует метод удаления, хотя он не описан в API
    // Если такого метода нет, нужно его реализовать на сервере
    try {
      await axios.delete('/api/v1/config/delete', {
        params: { name },
      });
      fetchTemplates();
    } catch (error) {
      console.error('Ошибка при удалении шаблона:', error);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Список Шаблонов
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} sx={{ mb: 2 }}>
        Добавить Шаблон
      </Button>
      <List>
        {templates.map((template) => (
          <ListItem key={template} sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemText primary={template} />
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpen(template)}
                sx={{ mr: 1 }}
              >
                Редактировать
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleDelete(template)}
              >
                Удалить
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{isEditing ? 'Редактировать Шаблон' : 'Новый Шаблон'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Название Шаблона"
            fullWidth
            value={currentTemplate.name}
            onChange={(e) =>
              setCurrentTemplate({ ...currentTemplate, name: e.target.value })
            }
            disabled={isEditing}
            margin="dense"
          />
          <TextField
            label="YAML Содержимое"
            fullWidth
            multiline
            rows={10}
            value={currentTemplate.compose}
            onChange={(e) =>
              setCurrentTemplate({ ...currentTemplate, compose: e.target.value })
            }
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleSave} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Templates;
